import React from 'react'
import { PageProps } from 'gatsby'

import * as styles from '../../../styles/pages/solutionsPage.module.scss'

import { SEO } from '../../../components/SEO'
import { SuccessCases } from '../../../components/SuccessCases'
import { AboutUs } from '../../../components/AboutUs'
import { Divider } from '../../../components/Divider'
import { Statistics } from '../../../components/Statistics'
import { Slider } from '../../../components/Slider'
import { SectionTitle } from '../../../components/SectionTitle'
import { Layout } from '../../../components/layout'
import {
  MethodologySection,
  FeaturesSection,
} from '../../../components/SolutionsPages'

import {
  faServer,
  faTools,
  faUserShield,
  faLevelDownAlt,
} from '@fortawesome/free-solid-svg-icons'

import banner1 from '../../../images/pages/bpm/aderenciaSistemas1.jpeg'
import banner2 from '../../../images/pages/bpm/aderenciaSistemas2.jpeg'

const slides = [
  {
    title: <>COMO ESCOLHER O SISTEMA DE GESTÃO IDEAL PARA O SEU NEGÓCIO</>,
    description: (
      <>
        Obtenha uma análise de aderência de sistemas para ser assertivo na
        escolha do melhor ERP para o seu negócio
      </>
    ),
    backgroundUrl: banner1,
    sameOrigin: true,
    link: `#contato`,
    linkText: `Fale Conosco`,
  },
  {
    title: <>APRIMORE A TOMADA DE DECISÕES TRABALHANDO COM DADOS CONFIÁVEIS</>,
    description: (
      <>
        A sistematização e automatização de processos por meio de softwares
        integrados de gestão se mostram vantajosas ferramentas para otimização
        de processos de negócios
      </>
    ),
    backgroundUrl: banner2,
    sameOrigin: true,
    link: `#contato`,
    linkText: `Fale Conosco`,
  },
]

const cases = [
  {
    title: `P4 Engenharia`,
    text: `Tarefas repetitivas passam a ser executadas por Robos`,
    quantity: 74,
  },
  {
    title: `SASCAR`,
    text: `Aderências ao sistema Totvs`,
    quantity: 32,
  },
  {
    title: `UNIMED Rio Preto`,
    text: `Projeto de transformção digital e redução de atividades manuais`,
    quantity: 96,
  },
  {
    title: `Okubo`,
    text: `Estruturação da área comercial e aumento da aderência aos sistemas da empresa`,
    quantity: 40,
  },
]

const AderenciaDeSistemas: React.FC<PageProps> = ({ location }) => {
  const featuresItems = [
    {
      faIcon: faServer,
      title: `Aderência de sistemas`,
      description: (
        <>
          O ERP ou Sistema Integrado de Gestão é peça fundamental para a tomada
          de decisão na gestão de um negócio. No entanto, para que o sistema se
          mostre vantajoso para otimizar processos, aprimorar a gestão e
          maximizar o lucro, é necessário que haja aderência entre o sistema a
          ser implantado e a forma como a empresa trabalha.
        </>
      ),
    },
    {
      faIcon: faTools,
      title: `O que fazer antes de implantar`,
      description: (
        <>
          Antes de escolher um sistema integrado de gestão para implantação é
          preciso que se tenha uma visão clara sobre a maturidade dos processos
          organizacionais. Os processos devem ser mapeados, melhorados e após
          deve ser feita a análise de aderência do sistema à organização. Sem o
          trabalho de análise de aderência, é provável que o sistema implantado
          fique subutilizado, aumentando o risco da operação.
        </>
      ),
    },
    {
      faIcon: faUserShield,
      title: `Independência e imparcialidade`,
      description: (
        <>
          A Valore atua desde o mapeamento dos processos, melhoria, análise de
          aderência de sistemas (ERP, CRM) até a gestão da implantação junto ao
          fornecedor vencedor da concorrência. Nosso trabalho é entendermos a
          real necessidade do cliente e buscarmos fornecedores capazes de
          entregarem o que realmente prometem. Afinal, não é uma boa ideia
          perguntar ao vendedor do software qual é o melhor software para seu
          negócio.
        </>
      ),
    },
    {
      faIcon: faLevelDownAlt,
      title: `Redução de custos e riscos`,
      description: (
        <>
          Ao contratar uma Consultoria especializada em processos antes de
          iniciar a implantação do sistema, além de garantir maior aderência do
          sistema aos processos organizacionais da empresa cliente, é possível
          reduzir os custos e os riscos do projeto de implantação, já que o
          próprio mapeamento dos processos organizacionais servirá como
          requisito de implantação (blueprint) para a empresa de software.
        </>
      ),
    },
  ]

  const methodologyItems = [
    {
      count: 1,
      title: 'Mapeamento de processos',
      description: (
        <>
          O primeiro passo para fazer uma boa análise de aderência de sistemas é
          começar mapeando os processos organizacionais da forma como são
          executados atualmente na empresa, o que é chamado de desenho AS IS na
          metodologia de gestão por processos BPM. Além dos fluxogramas, são
          identificados os atributos dos processos, como objetivos, metas,
          instruções de trabalho, regras de negócio, maturidade, dentre outros.
        </>
      ),
    },
    {
      count: 2,
      title: 'Melhoria de processos',
      description: (
        <>
          Após mapeados os processos (desenhos AS IS), são identificadas
          oportunidades de melhorias destes processos de acordo com boas
          práticas de mercado. A etapa das melhorias é importante, pois ajuda a
          não sistematizar erros, como por exemplo: retrabalhos, execesso de
          hierarquia de aprovações, dentre outros. Em seguida é desenhado o
          processo já otimizado, também chamado de TO BE.
        </>
      ),
    },
    {
      count: 3,
      title: 'Elaboração de RFP',
      description: (
        <>
          A partir do novo processo, já otimizado, é feito um cálculo matemático
          de quantas atividades do processo cada sistema atende. Assim obtém-se
          um percentual de aderência de cada fornecedor de software aos
          processos da empresa cliente. Com este trabalho é possível anexar os
          desenhos dos processos à RFP (pedido de proposta), fornecendo
          requisitos a serem atendidos pelas empresas de software.
        </>
      ),
    },
    {
      count: 4,
      title: 'Gestão da implantação',
      description: (
        <>
          Além da aderência do sistema aos processos organizacionais da empresa
          cliente, para a escolha do software, são analisadas as propostas
          recebidas pelo cliente, tanto tecnicamente quanto a reputação do
          fornecedor, experiência, suporte e preços. Após contratação, é feita a
          gestão da implantação de ponta a ponta, por meio de metodologias ágeis
          de gestão.
        </>
      ),
    },
  ]

  return (
    <Layout location={location} mainFolder={location.pathname}>
      <SEO title="BPM - Aderência de Sistemas - Valore Brasil" />
      <Slider slides={slides} />

      <section className={styles.solutions}>
        <div className="pt-3 pb-5">
          <div className="container">
            <SectionTitle
              title="Nosso Propósito"
              className="solutionsTitle"
              description="Propor soluções através de análises de aderência de sistemas ERP ou CRM para embasar as tomadas de decisões e aprimorar a gestão."
            />
          </div>
          <FeaturesSection items={featuresItems} />
        </div>

        <div className="container">
          <Divider />
        </div>

        <div className="py-5">
          <MethodologySection
            locationPath={location.pathname}
            items={methodologyItems}
            descripitionSection={`Conheça agora os 04 principais passos de nossa metodologia para
            escolher o software de gestão ideal para o seu negócio.`}
          />
        </div>
      </section>

      <SuccessCases listCases={cases} />
      <AboutUs />
      <Statistics />
    </Layout>
  )
}

export default AderenciaDeSistemas
